const LocationSelection = () => {
	return <>
		<h1>
			Şube Değiştiriliyor...
		</h1>
	</>
}

LocationSelection.getServerProps = ({ sharedData, match }) => {
	return new Promise((resolve) => {
		const newLocation = sharedData.locations.find(b => b.slug === match?.params?.slug);
		if(newLocation) {
			resolve({
				cookies: [
					{
						name: 'locationId',
						value: newLocation.id,
					}
				],
				redirect: {
					key: 'home'
				},
			})
		}
		else {
			resolve({
				notFound: true,
			})
		}
	});
}

export default LocationSelection